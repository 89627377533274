<template>

<app-content :loading="is.loading" :fullheight="true">

	<app-content-head title="Tickets" :is-app="true" />

	<app-content-body class="dashboard">

		<app-input-text :loading="is.searching" placeholder="Search event..." v-model="search" class="dashboard-search" />

		<div class="dashboard-results">

			<app-panel-item v-on:click="onEventClick(event.id)" v-for="event in events" :key="event.id" :text="event.name" :subtext="event.date | formatDate('h:mma, Do MMMM YYYY')" :caret="true" />

			<div class="dashboard-results-prompt" v-if="!is.failed && !events.length">

				<app-icon icon="search" />
				<p>Search event above</p>

			</div>

		</div>
		
	</app-content-body>

</app-content>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				initialised: false,
				loading: false,
				searching: false
			},
			search: '',
			events: []
		}

	},

	watch: {

		search: function() {

			this.onSearchChange()

		}

	},

	methods: {

		onSearchChange: function() {

			if (this.search) {

				this.is.searching = true
				this.is.failed = false

				this.$api.cancel()

				this.$api.get('tickets/search', {
					text: this.search
				}).then(function(json) {

					this.events = json.events

					this.is.searching = false

				}.bind(this))

			} else {

				this.is.searching = false
				this.badges = []

			}

		},

		onEventClick: function(id) {

			this.$router.push({
				name: 'Tickets.Event',
				params: {
					id: id
				}
			})

		}

	}

}

</script>

<style scoped>

.dashboard {
	padding: 0px!important;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	flex-shrink: 0;
}

.dashboard.is-failed {
	background-color: #c55b5b;
}

.dashboard-search {
	padding: 10px 10px 0px 10px;
}

.dashboard-search {
	margin-bottom: 5px!important;
}

.dashboard-results {
	flex-grow: 1;
}

.dashboard-results >>> .item {
	padding: 5px 12px;
	border-bottom: 1px solid #eee;
    margin: 0px;
}

.dashboard-results >>> .item:last-child {
	border-bottom: 0px;
}

.is-desktop .dashboard-results >>> .item:hover {
    background-color: rgba(0, 0, 0, 0.15);
	color: #333;
    border-radius: 0px;
    margin: 0px;
	padding: 5px 12px;
}

.dashboard-results >>> .item .item-icon {
    background-color: rgba(0, 0, 0, 0.15);
	color: #4d5b6e;
	font-size: 14px;
	width: 24px;
	height: 24px;
	line-height: 26px;
	border-radius: 50%;
}

.dashboard-results >>> .item[data-theme="green"] .item-icon {
    color: #fff;
	background-color: #4faa80;
}

.dashboard-results >>> .item[data-theme="yellow"] .item-icon {
    color: #fff;
	background-color: #ffbe7a;
}

.dashboard-results >>> .item-text {
    color: #333;
	font-size: 16px;
	font-weight: 300;
}

.dashboard-results >>> .item-text small {
	margin-top: 4px;
	opacity: 0.75;
}

.dashboard-results >>> .item:first-child {
    margin-top: 0px;
}

.dashboard-results-prompt {
	color: #9aa2ac;
	position: absolute;
	left: 50%;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.dashboard-results-prompt p small {
	display: block;
	font-size: 12px;
	font-weight: 300;
}

.dashboard.is-failed .dashboard-results-prompt {
	color: #fff;
}

.dashboard-results-prompt >>> .icon {
	font-size: 64px;
	margin-bottom: 10px;
}

.dashboard-results-prompt >>> p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.is-desktop .dashboard-results-prompt:hover {
	color: #4d5b6e;
}

</style>
